.work-carousel {
    background: linear-gradient(135deg, #f6f8fa 0%, #e9f1f7 100%);
    padding: 80px 0;
  }
  
  .work-carousel h2 {
    text-align: center;
    font-size: 2.5em;
    color: #667eea;
    margin-bottom: 40px;
  }
  
  .carousel-item {
    padding: 20px;
  }
  
  .carousel-content {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .carousel-content:hover {
    transform: translateY(-10px);
  }
  
  .carousel-content img {
    width: 100%;
    height: 500px;
    object-fit:contain;
  }
  
  .carousel-text {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background: linear-gradient(135deg, #a3b7f1 0%, #b48ebf 100%);
    color: #fff;
    transform: translateY(100%);
    transition: transform 0.3s ease;
  }
  
  .carousel-content:hover .carousel-text {
    transform: translateY(0);
  }
  
  .carousel-text h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .carousel-text p {
    font-size: 1em;
    line-height: 1.6;
  }
  
  /* Custom styling for Slick Carousel */
  .slick-dots {
    bottom: -40px;
  }
  
  .slick-dots li button:before {
    font-size: 12px;
    color: #3498db;
    opacity: 0.5;
  }
  
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }
  
  .slick-prev,
  .slick-next {
    z-index: 1;
    width: 40px;
    height: 40px;
  }
  
  .slick-prev {
    left: -50px;
  }
  
  .slick-next {
    right: -50px;
  }
  
  .slick-prev:before,
  .slick-next:before {
    font-size: 40px;
    color: #667eea;
  }
  
  @media (max-width: 768px) {
    .carousel-content img {
      height: 300px;
    }
  
    .slick-prev,
    .slick-next {
      display: none !important;
    }
  }