.about {
  padding: 100px 0;
  background: linear-gradient(135deg, #a3b7f1 0%, #b48ebf 100%);
  /* background: linear-gradient(135deg, #f6f8fa 0%, #e9f1f7 100%); */
  overflow: hidden;
}

.about h2 {
  text-align: center;
  color: #fff;
  font-size: 2.5em;
  margin-bottom: 50px;
  position: relative;
}

.about h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color:#667eea;
  margin: 20px auto 0;
}

.about-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.about-text {
  flex: 0 0 60%;
  padding-right: 50px;
}

.about-text h3 {
  color: #fff;
  font-size: 1.8em;
  margin-bottom: 20px;
}

.about-text p {
  color: #fff;
  line-height: 1.8;
  margin-bottom: 30px;
  font-size: 1.1em;
}

.about-stats {
  flex: 0 0 35%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.stat-item {
  flex: 0 0 48%;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.stat-number {
  display: block;
  font-size: 2.5em;
  font-weight: bold;
  color: #667eea;
  margin-bottom: 10px;
}

.stat-label {
  font-size: 1.1em;
  color: #2c3e50;
}

@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }
  
  .about-text, .about-stats {
    flex: 0 0 100%;
    padding-right: 0;
  }
  
  .about-stats {
    margin-top: 50px;
  }
}