.footer {
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  padding: 20px 0;
  font-size: 14px;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.box{
  flex:.5;
}

.footer-links {
  margin-bottom: 15px;
}

.footer-links a {
  color: #667eea;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #007bff;
}

.footer-copyright {
  color: #666;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-links a {
    margin: 5px 10px;
  }
}