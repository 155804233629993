.contact {
  padding: 100px 0;
  background: linear-gradient(135deg, #a3b7f1 0%, #b48ebf 100%);
  color: #ffffff;
}

.contact h2 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 50px;
  position: relative;
}

.contact h2::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #ffffff;
  margin: 20px auto 0;
}

.contact-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.contact-info {
  flex: 0 0 45%;
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.contact-info h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
}

.contact-info p {
  margin-bottom: 15px;
  font-size: 1.1em;
  line-height: 1.6;
  display: flex;
  align-items: center;
}

.contact-info p i {
  margin-right: 15px;
  font-size: 1.2em;
}

.social-links {
  margin-top: 30px;
}

.social-icon {
  color: #8366B3;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;  /* Increased from 40px */
  height: 50px; /* Increased from 40px */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  margin-right: 20px; /* Increased from 10px */
  transition: all 0.3s ease;
  font-size: 24px; /* Increased font size for larger icons */
}

.social-icon:last-child {
  margin-right: 0; /* Remove margin from the last icon */
}

.social-icon:hover {
  background: #ffffff;
  color: #b48ebf;
  transform: translateY(-5px);
}

.contact-form {
  flex: 0 0 50%;
  background: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #b48ebf;
  outline: none;
}

.form-group textarea {
  height: 150px;
}

.submit-btn {
  background: linear-gradient(135deg, #a3b7f1 0%, #b48ebf 100%);
  color: #ffffff;
  border: none;
  padding: 12px 30px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.submit-btn:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.success-message,
.error-message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
  }
  
  .contact-info,
  .contact-form {
    flex: 0 0 100%;
    margin-bottom: 30px;
  }
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

.contact-info, .contact-form {
  animation: float 6s ease-in-out infinite;
}