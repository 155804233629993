.floating-chatbot-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .chat-icon {
    width: 60px;
    height: 60px;
    background-color: #007bff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
  }
  
  .chat-icon:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }
  
  .chat-icon.active {
    background-color: #dc3545;
  }
  
  .chat-dialog {
    width: 350px;
    height: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    border: 1px solid #e0e0e0;
    animation: slideUp 0.3s ease;
  }
  
  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .chat-header h3 {
    margin: 0;
    font-size: 16px;
  }
  
  .chat-header button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #6c757d;
  }
  
  .message-list {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .message {
    max-width: 80%;
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 12px;
    word-wrap: break-word;
  }
  
  .message.user {
    background-color: #e6f2ff;
    align-self: flex-end;
    margin-left: auto;
  }
  
  .message.ai {
    background-color: #f0f0f0;
    align-self: flex-start;
  }
  
  .input-area {
    display: flex;
    padding: 10px;
    border-top: 1px solid #e0e0e0;
  }
  
  .input-area input {
    flex-grow: 1;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .input-area button {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  @keyframes slideUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }