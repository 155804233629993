.team {
    padding: 60px 0;
    background-color: #ffffff;
  }
  
  .team h2 {
    text-align: center;
    color: #667eea;
    margin-bottom: 40px;
    font-size: 2.5em;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 30px;
  }
  
  .team-member {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .team-member:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .member-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
    object-fit: cover;
  }
  
  .team-member h3 {
    color: #667eea;
    margin-bottom: 5px;
    font-size: 1.5em;
  }
  
  .team-member h4 {
    color: #7b8cd9;
    margin-bottom: 15px;
    font-size: 1.1em;
  }
  
  .team-member p {
    color: #555;
    font-size: 0.9em;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .team-grid {
      grid-template-columns: 1fr;
    }
  }